.saved-reports {
    padding: 16px 24px;
    max-width: 800px;
    margin: 0 auto;
    background: #f8f9fa;
    min-height: 100vh;
}

.report-list {
    display: grid;
    gap: 8px;
    width: 100%;
    transition: all 0.3s ease;
}

.report-list.hidden {
    display: none;
}

.report-item {
    background: #fff;
    border-radius: 0;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: background 0.1s ease;
}

.report-item:hover {
    background: #f1f3f4;
    border-color: #dadce0;
}

.report-item:active {
    background: #e8f0fe;
}

.report-item input {
    flex: 1;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background: #1a73e8;
    cursor: text;
    margin-right: 8px;
}

.report-item input:focus {
    outline: none;
    background: #1557b0;
}

.report-item input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.report-item span {
    flex: 1;
    font-family: 'Google Sans', Roboto, Arial, sans-serif;
    font-size: 14px;
    color: #202124;
    padding: 8px;
    cursor: pointer;
}

.button-group {
    display: flex;
    gap: 8px;
    align-items: center;
}

.report-item:hover .button-group {
    opacity: 1;
}

.report-card {
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    margin-top: 8px;
    animation: slideIn 0.3s ease;
    height: calc(100vh - 200px);
    overflow-y: auto;
    margin-left: 0;
}

.report-card-header {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid #dadce0;
    z-index: 1;
}

.close-button,
.delete-button {
    background: transparent;
    color: #5f6368;
    padding: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: all 0.2s ease;
    margin-left: 8px;
    box-shadow: none;
}

.close-button:hover,
.delete-button:hover {
    background: rgba(95, 99, 104, 0.1);
    color: #202124;
    transform: none;
}

.close-button svg,
.delete-button svg {
    width: 16px;
    height: 16px;
}

.report-content {
    padding: 0;
    max-width: 800px;
    margin: 0;
    height: calc(100vh - 280px);
    overflow: hidden;
}

.editor-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    scroll-padding: 20px;
    padding: 0 24px;
}

.report-card-header h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #202124;
    width: 100%;
}

button {
    padding: 8px 12px;
    border-radius: 4px;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
}

.edit-button {
    background: #1a73e8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    height: 36px;
}

.edit-button:hover {
    background: #1557b0;
    box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
    transform: translateY(-1px);
}

.edit-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.delete-button {
    background: transparent;
    color: #5f6368;
}

.delete-button:hover {
    background: rgba(95, 99, 104, 0.1);
    box-shadow: none;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-slate-editor="true"] {
    padding: 20px;
    min-height: 100%;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-family: inherit;
    font-size: 0.9rem;
    line-height: 1.6;
}

[data-slate-node="element"] {
    margin: 0.4em 0;
}

[data-slate-node="element"][data-slate-type="heading"] {
    font-weight: bold;
    font-size: 1.1em;
    margin: 1em 0 0.2em;
    color: #2c3e50;
}

[data-slate-node="element"][data-slate-type="indented"] {
    padding-left: 20px;
    margin: 0.4em 0;
}

/* Add search styles that match existing design */
.search-container {
    padding: 16px 16px 12px;
    background: #fff;
    border-bottom: 1px solid #dadce0;
    margin-bottom: 12px;
}

.search-input {
    width: 100%;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 14px;
    padding: 10px 16px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    color: #202124;
    background: #f8f9fa;
    transition: all 0.2s ease;
}

.search-input:focus {
    outline: none;
    background: #fff;
    border-color: #1a73e8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.search-input::placeholder {
    color: #5f6368;
}

/* Update button styles to match existing */
.edit-button,
.save-button,
.cancel-button {
    padding: 8px 16px;
    border-radius: 8px;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    border: none;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.edit-button {
    background: #1a73e8;
    color: white;
}

.edit-button:hover {
    background: #1557b0;
    box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
    transform: translateY(-1px);
}

.save-button {
    background: #31a3d8;
    color: white;
}

.save-button:hover {
    background: #15627b;
    box-shadow: 0 2px 6px rgba(30, 142, 62, 0.3);
    transform: translateY(-1px);
}

.cancel-button {
    background: #5f6368;
    color: white;
}

.cancel-button:hover {
    background: #202124;
    box-shadow: 0 2px 6px rgba(95, 99, 104, 0.3);
    transform: translateY(-1px);
}

/* Update report actions to match existing design */
.report-actions {
    display: flex;
    gap: 8px;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.report-item:hover .report-actions {
    opacity: 1;
}

/* Update editing state styles */
.report-content.editing {
    padding: 16px 24px;
    background: #fff;
}

[data-slate-editor="true"].editing {
    border: 1px solid #dadce0;
    border-radius: 4px;
    background: #fff;
    min-height: 200px;
}

/* Add loader styles */
.reports-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;
}

.reports-loader {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #1a73e8;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.load-button {
    background-color: #1a73e8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
}

.load-button:hover {
    background-color: #1557b0;
    box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
    transform: translateY(-1px);
}

.load-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

/* Update button group spacing */
.button-group {
    display: flex;
    gap: 8px;
    align-items: center;
}

/* Ensure consistent button heights */
.button-group button {
    height: 36px;
}

/* Style the FaEdit icon */
.edit-button svg {
    width: 16px;
    height: 16px;
}

/* Update the report item edit button */
.report-item .edit-button {
    background: transparent;
    color: #5f6368;
    padding: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: all 0.2s ease;
    margin-left: 8px;
    box-shadow: none;
}

.report-item .edit-button:hover {
    background: rgba(95, 99, 104, 0.1);
    color: #202124;
    transform: none;
    box-shadow: none;
}

.report-item .edit-button svg {
    width: 16px;
    height: 16px;
}

/* Update the report card edit button */
.report-card .edit-button {
    background: #1a73e8;
    color: white;
    padding: 8px 16px;
    /* standard padding */
    min-width: 80px;
    /* half the previous width */
    border-radius: 8px;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    border: none;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    height: 36px;
}

.report-card .edit-button:hover {
    background: #1557b0;
    box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
    transform: translateY(-1px);
}

.report-card .edit-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

/* Common styles for main action buttons in report card */
.report-card .load-button,
.report-card .edit-button,
.report-card .print-button,
.report-card .pdf-button {
    background: #1a73e8;
    color: white;
    padding: 8px 16px;
    height: 36px;
    min-width: 80px;
    border-radius: 8px;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    border: none;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

/* Hover state for report card buttons */
.report-card .load-button:hover,
.report-card .edit-button:hover,
.report-card .print-button:hover,
.report-card .pdf-button:hover {
    background: #1557b0;
    box-shadow: 0 2px 6px rgba(26, 115, 232, 0.3);
    transform: translateY(-1px);
}

/* Active state for report card buttons */
.report-card .load-button:active,
.report-card .edit-button:active,
.report-card .print-button:active,
.report-card .pdf-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

/* Keep existing styles for report list buttons */
.report-item .edit-button,
.report-item .delete-button,
.report-item .close-button {
    background: transparent;
    color: #5f6368;
    padding: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 4px;
}

/* Update report card header layout for opened reports */
.report-card .report-card-header {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid #dadce0;
    z-index: 1;
}

.report-card .report-card-header h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #202124;
    width: 100%;
}

/* Keep report list items horizontal */
.report-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
}

.report-item .button-group {
    display: flex;
    gap: 8px;
    align-items: center;
}

/* Common styles for main action buttons in report card */
.report-card .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    width: 100%;
}