.about-page {
    font-family: 'Arial', sans-serif;
    color: #4a4a4a;
    line-height: 1.6;
}

.about-hero {
    background: linear-gradient(135deg, #61b6e7, #1e88e5);
    text-align: center;
    color: white;
    padding: 100px 20px;
    margin-bottom: 60px;
}

.about-hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: white;
}

.about-hero p {
    font-size: 1.5rem;
    color: white;
}

.about-mission,
.about-features,
.about-stats,
.about-testimonial,
.about-cta {
    max-width: 1200px;
    margin: 0 auto 80px;
    padding: 0 20px;
}

.about-mission {
    max-width: 1200px;
    margin: 0 auto 100px;
    padding: 60px 20px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.about-mission h2 {
    font-size: 4rem;
    margin-bottom: 30px;
    color: #1565c0;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.about-mission p {
    font-size: 2rem;
    line-height: 1.5;
    color: #2c3e50;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    font-weight: 500;
}

.about-features h2,
.about-stats h2,
.about-cta h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 40px;
    color: #2c3e50;
    font-weight: bold;
}

.feature-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-item {
    background: white;
    padding: 35px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    border: 1px solid rgba(21, 101, 192, 0.1);
}

.feature-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
    border-color: rgba(21, 101, 192, 0.3);
}

.feature-item h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #1565c0;
    font-weight: bold;
}

.feature-item p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #4a4a4a;
}

.stats-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
}

.stat-item {
    text-align: center;
    flex: 1;
    min-width: 200px;
}

.stat-item h3 {
    font-size: 3rem;
    color: #1565c0;
    margin-bottom: 10px;
}

.about-testimonial {
    background-color: #f4f7f9;
    padding: 60px 40px;
    border-radius: 10px;
    text-align: center;
}

.about-testimonial blockquote {
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 20px;
}

.about-cta {
    text-align: center;
    background: linear-gradient(135deg, #61b6e7, #1e88e5);
    padding: 80px 20px;
    border-radius: 15px;
    color: white;
    margin-bottom: 80px;
}

.about-cta h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: white;
    font-weight: bold;
}

.about-cta p {
    font-size: 1.4rem;
    margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button {
    background: rgba(18, 193, 251, 0.907);
    color: #ffffff;
    border: none;
    padding: 1rem 2.5rem;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    background: #04a7e7;
}

.cta-button:active {
    transform: translateY(0);
}

.fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 400ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, visibility;

}

.fade-in-section.show {
    opacity: 1;
    visibility: visible;

}

@media (max-width: 768px) {
    .about-hero {
        padding: 40px 15px;
    }

    .about-hero h1 {
        font-size: 1.8rem;
    }

    .about-hero p {
        font-size: 1rem;
    }

    .about-mission h2,
    .about-features h2,
    .about-stats h2,
    .about-cta h2 {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .about-mission p {
        font-size: 1.1rem;
        padding: 0;
    }

    .feature-grid {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 10px;
    }

    .feature-item {
        padding: 20px;
    }

    .stat-item h3 {
        font-size: 2rem;
    }

    .about-cta {
        padding: 40px 15px;
        margin-bottom: 40px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .about-hero {
        padding: 60px 20px;
    }

    .feature-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }

    .about-mission h2 {
        font-size: 2.5rem;
    }

    .about-mission p {
        font-size: 1.4rem;
    }

    .stat-item h3 {
        font-size: 2.5rem;
    }
}