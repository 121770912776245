:root {
    --primary: #339ee4;
    --primary-dark: #1d4ed8;
    --secondary: #64748b;
    --bg-light: #f8fafc;
    --bg-white: #ffffff;
    --text-dark: #1e293b;
    --text-light: #94a3b8;
    --border: #e2e8f0;
    --shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.qq-container {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100dvh - 48px);
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #71cbff, #afe8ffc0, #ccecff9a);
    border-radius: 16px;
}

.qq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 8px;

}

.qq-container h2 {
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    letter-spacing: -0.5px;
}




.qq-chat-container {
    background: var(--bg-white);
    border-radius: 16px;
    box-shadow: var(--shadow-lg);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    border: 1px solid var(--border);
}

.qq-messages-container {
    padding: 32px;
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: linear-gradient(to bottom, var(--bg-light), var(--bg-white));
}

.welcome-message {
    text-align: center;
    color: var(--text-light);
    padding: 48px 24px;
}

.welcome-message h3 {
    color: var(--text-dark);
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 600;
}

.qq-message {
    display: flex;
    margin-bottom: 8px;
    max-width: 85%;
}

.qq-message.user {
    justify-content: flex-end;
    margin-left: auto;
}

.qq-message-content {
    position: relative;
    padding: 16px 20px;
    border-radius: 16px;
    font-size: 15px;
    line-height: 1.6;
    white-space: pre-wrap;
    box-shadow: var(--shadow);
}

.user .qq-message-content {
    background: var(--primary);
    color: white;
    border-radius: 16px 16px 4px 16px;
}

.assistant .qq-message-content {
    background: #c2ecff50;
    color: var(--text-dark);
    border-radius: 16px 16px 16px 4px;
    padding: 12px 16px;
    font-size: 14px;
    /* line-height: 1.2; */

    /* Headers */
    & h3 {
        font-size: 17px;
        font-weight: 700;
        margin: 16px 0 4px;
        display: block;
        color: var(--text-dark);
    }

    /* First header shouldn't have top margin */
    & h3:first-child {
        margin-top: 0;
    }

    /* Double spacing between sections */
    & h3:not(:first-child) {
        margin-top: 20px;
    }

    /* Bold text */
    & strong {
        font-weight: 600;
        color: #1a1a1a;
    }

    /* Lists */
    & li {
        margin: 4px 0;
        line-height: 1.3;
        list-style: none;
        display: flex;
        gap: 8px;
    }

    /* Numbers */
    & .number {
        min-width: 20px;
        text-align: right;
    }

    /* List content */
    & li>div {
        flex: 1;
    }

    /* Nested lists */
    & li li {
        margin: 2px 0;
        margin-left: 28px;
        /* Indent nested items */
    }

    /* Remove extra spacing */
    & * {
        margin-block: 0;
        padding-block: 0;
    }
}

.qq-clear-button {
    padding: 10px 20px;
    background: #60a5fa;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
    backdrop-filter: blur(8px);
}

.qq-clear-button:hover {
    background: #3b82f6;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(37, 99, 235, 0.3);
}






.qq-input-form {
    display: flex;
    gap: 12px;
    padding: 24px;
    border-top: 1px solid var(--border);
    background: var(--bg-white);
    align-items: flex-end;
    position: relative;
}

.qq-input-wrapper {
    display: flex;
    gap: 12px;
    flex-grow: 1;
    align-items: flex-end;
    position: relative;
}

.qq-input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    min-height: 56px;
}

.qq-collapse-button {
    position: absolute;
    top: 16px;
    right: 16px;
    transform: none;
    padding: 4px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--secondary);
    z-index: 2;
}

.qq-message-input:focus~.qq-collapse-button,
.qq-message-input:not([style*="height: 56px"])~.qq-collapse-button,
.qq-message-input:not(:placeholder-shown)~.qq-collapse-button {
    opacity: 1;
}

.qq-message-input:focus {
    border-color: var(--primary);
    background: var(--bg-white);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.qq-message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px 40px 16px 16px;
    border: 2px solid var(--border);
    border-radius: 12px;
    font-size: 15px;
    outline: none;
    transition: all 0.2s;
    background: var(--bg-light);
    min-height: 56px;
    max-height: 400px;
    line-height: 24px;
    resize: none;
    overflow-y: hidden;
    transform-origin: bottom;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.qq-message-input::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
}

/* Only show scrollbar when content exists and height is maxed */
.qq-message-input:not(:empty) {
    overflow-y: auto;
}

.qq-send-button {
    height: 56px;
    width: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: var(--shadow);
    flex-shrink: 0;
}

.qq-send-button:hover:not(:disabled) {
    background: var(--primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-lg);
}

.qq-send-button:disabled {
    background: var(--secondary);
    cursor: not-allowed;
    opacity: 0.7;
}

.typing-indicator {
    display: flex;
    gap: 6px;
    padding: 12px 0;
}

.typing-indicator span {
    width: 10px;
    height: 10px;
    background: var(--primary);
    border-radius: 50%;
    opacity: 0.4;
    animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
    animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

.loading {
    min-width: 80px;
}

@media (max-width: 768px) {
    .qq-container {
        padding: 12px;
        min-height: calc(100dvh - 120px);
        margin: 60px 0;
        border-radius: 0;
        position: relative;
        overflow: visible;
    }

    .qq-header {
        margin-bottom: 12px;
        padding: 0 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    }

    .qq-container h2 {
        font-size: 22px;
        flex: 1;
        margin-right: 8px;
    }

    .qq-chat-container {
        border-radius: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        min-height: calc(100dvh - 240px);
    }

    .qq-messages-container {
        padding: 16px;
        overflow-y: auto;
        flex: 1;
    }

    .qq-input-form {
        padding: 12px;
        position: relative;
        background: var(--bg-white);
    }

    .qq-clear-button {
        padding: 8px 12px;
        font-size: 12px;
        background: rgba(96, 165, 250, 0.9);
        backdrop-filter: blur(4px);
        white-space: nowrap;
        min-width: auto;
    }

    .qq-clear-button-input {
        padding: 6px 10px;
        font-size: 12px;
    }
}

.qq-copy-button {
    position: relative;
    margin-top: 0;
    padding: 4px 6px;
    background: white;
    border: 1px solid var(--border);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    color: var(--secondary);
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.qq-copy-button.copied {
    margin-top: 8px;
    background: var(--primary);
    color: white;
    border-color: var(--primary);
}


.qq-copy-button::after {
    content: 'Copy';
    font-size: 12px;
    font-weight: 500;
}

.qq-copy-button svg {
    width: 18px;
    height: 18px;
}

.qq-copy-button:hover {
    background: var(--bg-light);
    color: var(--primary);
    transform: translateY(-1px);
    box-shadow: var(--shadow);
}



.qq-suggestions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 24px;
    animation: fadeIn 0.5s ease-out;
}

.suggestion-box {
    background: var(--bg-white);
    border: 1px solid var(--border);
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: var(--shadow);
}

.suggestion-box:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
    border-color: #71cbff;
}

.suggestion-box h4 {
    color: #71cbff;
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 600;
}

.suggestion-box p {
    color: var(--text-dark);
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .qq-suggestions {
        grid-template-columns: 1fr;
    }
}

.qq-button-group {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.qq-print-button {
    padding: 4px 6px;
    background: white;
    border: 1px solid var(--border);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    color: var(--secondary);
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.qq-print-button::after {
    content: 'Print';
    font-size: 12px;
    font-weight: 500;
}

.qq-print-button:hover {
    background: var(--bg-light);
    color: var(--primary);
    transform: translateY(-1px);
    box-shadow: var(--shadow);
}

.qq-disclaimer {
    margin-top: 15px;
    padding: 4px 8px;
    background-color: #cdf1ffad;
    border: 1px solid #bae7ff;
    border-radius: 4px;
    color: #626262;
    text-align: left;
}

.qq-disclaimer strong {
    display: block;
    margin-bottom: 2px;
    font-size: 0.6rem !important;
    text-align: left;
}

.qq-disclaimer p {
    margin: 1px 0;
    font-size: 0.6rem !important;
    line-height: 1.1;
    color: #626262;
    text-align: left;
}

.qq-collapse-button:hover {
    color: var(--primary);
}