.profile-container {
    padding: 24px;
    max-width: 1200px;
    width: calc(100% - 48px);
    margin: 24px auto;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.profile-loading {
    text-align: center;
    font-size: 1.1rem;
    color: #666;
    padding: 1rem;
}

.profile-header {
    text-align: center;
    margin-top: 2rem;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #1565c0;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
}

.profile-header h2 {
    margin: 0.3rem 0;
    color: #333;
    font-size: 1.5rem;
}

.profile-email {
    color: #666;
    font-size: 1rem;
}

.profile-details {
    margin-bottom: 1.5rem;
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
}

.profile-details h3 {
    color: #1565c0;
    border-bottom: 2px solid rgba(21, 101, 192, 0.2);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.profile-info {
    display: grid;
    gap: 0.8rem;
}

.info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    border-radius: 6px;
    background: #ffffff;
    transition: transform 0.2s ease;
}

.info-label {
    font-weight: 600;
    color: #444;
    font-size: 0.9rem;
}

.info-value {
    color: #333;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 4px;
}

.profile-actions {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
}

.profile-button {
    padding: 0.7rem 1.5rem;
    background-color: #4283cc;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.status-active,
.status-inactive {
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
}

.status-active {
    color: #3acd27;
}

.status-inactive {
    color: #f44336;
}

.status-active::after,
.status-inactive::after {
    padding: 0.1rem 0.4rem;
    border-radius: 4px;
    font-size: 0.8rem;
}

.status-active::after {
    content: "✓";
    background: rgba(33, 150, 243, 0.1);
}

.status-inactive::after {
    content: "×";
    background: rgba(244, 67, 54, 0.1);
}

.profile-button.primary {
    background-image: linear-gradient(45deg, #4CAF50, #45a049);
}

@media (max-width: 768px) {
    .profile-container {
        width: 100%;
        margin: 0;
        padding: 16px;
        height: 100vh;
        border-radius: 0;
        margin-top: 60px;
        /* Account for mobile header */
    }

    /* Adjust header spacing */
    .profile-header {
        margin-top: 1rem;
    }

    /* Make info items stack vertically */
    .info-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        padding: 12px;
        background: #f8f9fa;
        border-radius: 8px;
    }

    .info-label {
        font-size: 0.8rem;
        color: #666;
    }

    .info-value {
        font-size: 1rem;
        width: 100%;
    }

    /* Adjust subscription status display */
    .subscription-end {
        display: block;
        margin-left: 0;
        margin-top: 4px;
    }

    /* Stack action buttons */
    .profile-actions {
        flex-direction: column;
        gap: 12px;
        padding: 0 16px;
    }

    .profile-button {
        width: 100%;
        padding: 12px;
        font-size: 1rem;
    }

    /* Adjust pricing section */
    .pricing-section {
        margin-top: 1rem;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

.subscription-end {
    font-size: 0.9em;
    color: #666;
    margin-left: 8px;
}

.pricing-section {
    margin-top: -4rem;
    margin-bottom: 2rem;
    text-align: center;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

.pricing-section h3 {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    color: #333;
}

.pricing-subtext {
    margin-bottom: 2.5rem;
    color: #666;
    font-size: 1.1rem;
}

.pricing-container {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin: 2rem auto;
    flex-wrap: nowrap;
    padding: 0 24px;
    max-width: 1200px;
}

.pricing-card {
    flex: 0 0 320px;
    min-width: 320px;
    max-width: 320px;
    min-height: 420px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(21, 101, 192, 0.1);
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(21, 101, 192, 0.1);
}

.pricing-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 32px rgba(21, 101, 192, 0.15);
    border-color: rgba(21, 101, 192, 0.2);
}

.pricing-header {
    min-height: 140px;
    padding: 0 0 16px 0;
    border-bottom: 1px solid rgba(21, 101, 192, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
}

.pricing-header h3 {
    font-size: 24px;
    font-weight: 700;
    color: #1565C0;
    margin-bottom: 16px;
    letter-spacing: -0.5px;
}

.price {
    font-size: 48px;
    font-weight: 700;
    color: #1a1a1a;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
    letter-spacing: -1px;
    margin: 4px 0;
}

.price span {
    font-size: 16px;
    color: #666;
    font-weight: 500;
    letter-spacing: normal;
}

.savings {
    margin: 4px 0 0 0;
    padding: 4px 12px;
    background: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    align-self: center;
}

.pricing-features {
    margin: 16px 0 0 0;
    margin-top: 0rem;
    padding: 0;
    list-style: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pricing-features li {
    padding: 8px 0;
    color: #444;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid rgba(21, 101, 192, 0.05);
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
}

.pricing-features li:last-child {
    border-bottom: none;
}

.pricing-features li::before {
    content: "✓";
    color: #1565C0;
    font-weight: bold;
    background: rgba(21, 101, 192, 0.1);
    min-width: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    flex-shrink: 0;
}

.pricing-footer {
    margin-top: auto;
    padding-top: 16px;
}

.subscribe-button {
    padding: 16px 24px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    background: linear-gradient(135deg, #1976D2, #1565C0);
    box-shadow: 0 4px 12px rgba(21, 101, 192, 0.2);
}

.subscribe-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(21, 101, 192, 0.3);
    background: linear-gradient(135deg, #1E88E5, #1976D2);
}

.pricing-card.free {
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-card.free .pricing-header h3 {
    color: #495057;
}

.trial-button {
    padding: 16px 24px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    background: linear-gradient(135deg, #6c757d, #495057);
    box-shadow: 0 4px 12px rgba(108, 117, 125, 0.2);
}

.trial-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(108, 117, 125, 0.3);
    background: linear-gradient(135deg, #495057, #343a40);
}

.ribbon {
    position: absolute;
    top: 20px;
    right: -15px;
    padding: 5px 15px;
    background: #2196F3;
    color: white;
    font-size: 0.8rem;
    border-radius: 3px;
    transform: rotate(45deg);
}

.cancel-subscription {
    background-color: #8f8f8f !important;
    color: white !important;
}

.cancel-subscription:hover {
    background-color: #c82333 !important;
}

.profile-actions button {
    margin: 0 10px;
}

.profile-actions button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.profile-actions button:last-child {
    background-color: #3282d3;
    color: white;
}

.profile-actions button:last-child:hover:not(:disabled) {
    background-color: #487cd1;
}

.price-options {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: center;
}

.price.yearly {
    color: #1976D2;
    font-size: 2rem;
}

@media (max-width: 1024px) {
    .pricing-container {
        flex-wrap: wrap;
        gap: 32px;
    }

    .pricing-card {
        flex: 1 1 calc(50% - 16px);
        min-width: 280px;
        max-width: none;
    }
}

@media (max-width: 768px) {
    .pricing-card {
        flex: 1 1 100%;
    }
}

.subscription-plan {
    color: #1565C0;
    font-size: 0.9em;
    margin-left: 4px;
}

.pricing-features li:first-child {
    padding-top: 0;
}