.checkout-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.checkout-header {
    text-align: center;
    margin-bottom: 32px;
}

.checkout-header h3 {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    color: #333;
}

.checkout-subtitle {
    margin-bottom: 2.5rem;
    color: #666;
    font-size: 1.1rem;
}

/* Copy pricing styles with checkout- prefix */
.checkout-pricing-container {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin: 2rem auto;
    flex-wrap: nowrap;
    padding: 0 24px;
    max-width: 1200px;
}

.checkout-pricing-card {
    flex: 0 0 320px;
    min-width: 320px;
    max-width: 320px;
    min-height: 420px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(21, 101, 192, 0.1);
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(21, 101, 192, 0.1);
}

.checkout-pricing-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 32px rgba(21, 101, 192, 0.15);
    border-color: rgba(21, 101, 192, 0.2);
}

.checkout-pricing-header {
    min-height: 140px;
    padding: 0 0 16px 0;
    border-bottom: 1px solid rgba(21, 101, 192, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0rem;
}

.checkout-pricing-header h3 {
    font-size: 24px;
    font-weight: 700;
    color: #1565C0;
    margin-bottom: 16px;
    letter-spacing: -0.5px;
}

.checkout-price {
    font-size: 48px;
    font-weight: 700;
    color: #1a1a1a;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
    letter-spacing: -1px;
    margin: 4px 0;
}

.checkout-price span {
    font-size: 16px;
    color: #666;
    font-weight: 500;
    letter-spacing: normal;
}

.checkout-savings {
    margin: 4px 0 0 0;
    padding: 4px 12px;
    background: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    align-self: center;
}

.checkout-pricing-features {
    margin: 32px 0;
    padding: 0;
    list-style: none;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.checkout-pricing-features li {
    padding: 8px 0;
    color: #444;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid rgba(21, 101, 192, 0.05);
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
}

.checkout-pricing-features li:last-child {
    border-bottom: none;
    padding-bottom: 24px;
}

.checkout-pricing-features li::before {
    content: "✓";
    color: #1565C0;
    font-weight: bold;
    background: rgba(21, 101, 192, 0.1);
    min-width: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    flex-shrink: 0;
}

.checkout-pricing-card.free {
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.checkout-pricing-card.free .checkout-pricing-header h3 {
    color: #495057;
}

.checkout-trial-button,
.checkout-subscribe-button {
    padding: 16px 24px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
}

.checkout-trial-button {
    background: linear-gradient(135deg, #6c757d, #495057);
    box-shadow: 0 4px 12px rgba(108, 117, 125, 0.2);
}

.checkout-subscribe-button {
    background: linear-gradient(135deg, #1976D2, #1565C0);
    box-shadow: 0 4px 12px rgba(21, 101, 192, 0.2);
}

.checkout-trial-button:hover,
.checkout-subscribe-button:hover {
    transform: translateY(-2px);
}

.checkout-trial-button:hover {
    box-shadow: 0 6px 16px rgba(108, 117, 125, 0.3);
    background: linear-gradient(135deg, #495057, #343a40);
}

.checkout-subscribe-button:hover {
    box-shadow: 0 6px 16px rgba(21, 101, 192, 0.3);
    background: linear-gradient(135deg, #1E88E5, #1976D2);
}

/* Media queries */
@media (max-width: 1024px) {
    .checkout-pricing-container {
        flex-wrap: wrap;
        gap: 32px;
    }

    .checkout-pricing-card {
        flex: 1 1 calc(50% - 16px);
        min-width: 280px;
        max-width: none;
    }
}

@media (max-width: 768px) {
    .checkout-container {
        padding: 12px;
        margin-top: 60px;
        /* Account for mobile header */
    }

    .checkout-pricing-container {
        padding: 0;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .checkout-pricing-card {
        width: 100%;
        min-width: unset;
        max-width: 100%;
        min-height: unset;
        margin: 0 auto;
    }

    .checkout-header {
        margin-bottom: 16px;
        padding: 0 12px;
    }

    .checkout-header h3 {
        font-size: 1.5rem;
    }

    .checkout-subtitle {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
        padding: 0 8px;
    }

    /* Make buttons more tappable on mobile */
    .checkout-trial-button,
    .checkout-subscribe-button {
        padding: 16px;
        min-height: 52px;
    }

    /* Adjust footer spacing */
    .checkout-footer {
        flex-direction: column;
        gap: 12px;
        align-items: stretch;
    }

    .checkout-back-button,
    .cancel-subscription {
        width: 100%;
        padding: 12px;
    }
}

/* Add these styles for the cancel subscription button */
.cancel-subscription {
    padding: 8px 24px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    background-color: #8f8f8f;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.cancel-subscription:hover {
    background-color: #c82333;
    transform: translateY(-2px);
}

.cancel-subscription:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Fix the footer styles */
.checkout-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    padding: 16px 0;
    border-top: 1px solid #eee;
    width: 100%;
}

.checkout-back-button {
    padding: 8px 24px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #e0e0e0;
    background: white;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease;
}

.checkout-back-button:hover {
    background: #f5f5f5;
    transform: translateY(-2px);
}

/* Add this to ensure all feature lists start at the same height */
.checkout-pricing-card .checkout-pricing-header {
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Optional: if you want to ensure the features are perfectly aligned */
.checkout-pricing-card {
    display: flex;
    flex-direction: column;
}

.checkout-pricing-card>* {
    flex-shrink: 0;
}

.checkout-pricing-card>.checkout-footer {
    margin-top: auto;
    padding-top: 16px;
    border-top: none;
}

/* Add specific margin for cards with savings tag */
.checkout-pricing-card:has(.checkout-savings) .checkout-pricing-features {
    margin-top: 16px;
}

/* Cards without savings tag keep original margin */
.checkout-pricing-card:not(:has(.checkout-savings)) .checkout-pricing-features {
    margin-top: 32px;
}

.checkout-container.embedded {
    padding: 0;
    box-shadow: none;
    background: transparent;
}

.checkout-container.embedded .checkout-options {
    padding: 0;
}

.checkout-container.embedded .checkout-header {
    padding-top: 0;
}

.checkout-enterprise-section {
    text-align: center;
    margin: 2rem auto;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 12px;
    max-width: 800px;
    border: 1px solid rgba(21, 101, 192, 0.1);
}

.checkout-enterprise-section h3 {
    color: #1565C0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.checkout-enterprise-section p {
    color: #666;
    font-size: 1.1rem;
}

.checkout-enterprise-section a {
    color: #1565C0;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.checkout-enterprise-section a:hover {
    color: #1976D2;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .checkout-enterprise-section {
        margin: 1.5rem 1rem;
        padding: 1.5rem;
    }

    .checkout-enterprise-section h3 {
        font-size: 1.1rem;
    }

    .checkout-enterprise-section p {
        font-size: 1rem;
    }
}

.checkout-access-code-section {
    text-align: center;
    margin: 2rem auto;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 12px;
    max-width: 800px;
    border: 1px solid rgba(21, 101, 192, 0.1);
}

.checkout-access-code-section h3 {
    color: #1565C0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.checkout-access-code-button {
    background: linear-gradient(135deg, #1976D2, #1565C0);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(21, 101, 192, 0.2);
}

.checkout-access-code-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(21, 101, 192, 0.3);
    background: linear-gradient(135deg, #1E88E5, #1976D2);
}

.access-code-form {
    max-width: 400px;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.access-code-input {
    padding: 12px;
    border: 1px solid rgba(21, 101, 192, 0.2);
    border-radius: 8px;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: border-color 0.3s ease;
}

.access-code-input:focus {
    outline: none;
    border-color: #1976D2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

.access-code-submit {
    background: linear-gradient(135deg, #1976D2, #1565C0);
    color: white;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(21, 101, 192, 0.2);
}

.access-code-submit:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(21, 101, 192, 0.3);
    background: linear-gradient(135deg, #1E88E5, #1976D2);
}

.access-code-error {
    color: #d32f2f;
    font-size: 0.9rem;
    text-align: center;
    padding: 8px;
    background: rgba(211, 47, 47, 0.1);
    border-radius: 4px;
}

@media (max-width: 768px) {
    .checkout-access-code-section {
        margin: 1.5rem 1rem;
        padding: 1.5rem;
    }

    .checkout-access-code-section h3 {
        font-size: 1.1rem;
    }

    .access-code-form {
        max-width: 100%;
    }

    .access-code-input,
    .access-code-submit {
        padding: 14px;
        font-size: 1rem;
    }
}