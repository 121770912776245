/* src/pages/Dashboard.css */

/* Remove the navbar height variable */
:root {
    --sidebar-width: 200px;
    --sidebar-collapsed-width: 60px;
}

/* Dashboard Container */
.dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f4f7fa;
}

/* Sidebar Styling */
.sidebar {
    width: var(--sidebar-width);
    background-color: #65a8d2;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    flex-direction: column;
}

.sidebar-collapsed .sidebar {
    width: var(--sidebar-collapsed-width);
}

/* Sidebar Logo */
.sidebar-logo {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebar-logo a {
    color: white;
    text-decoration: none;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    gap: 10px;
}

.logo-img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

/* Update collapsed state styles */
.sidebar-collapsed .sidebar-logo {
    padding: 10px;
}

.sidebar-collapsed .logo-text {
    display: none;
}

/* Remove these unused styles */
.sidebar-favicon {
    display: none;
}

.sidebar-collapsed .sidebar-favicon {
    display: none;
}

/* Sidebar Menu */
.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Sidebar Items */
.sidebar-item {
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: background-color 0.2s ease-in-out;
    position: relative;
}

.sidebar-item:hover {
    background-color: #3f8ddb66;
}

.sidebar-item a,
.sidebar-item .sidebar-link {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    /* Slightly smaller font */
    padding: 15px 20px;
    /* Adjusted padding */
    transition: color 0.2s ease-in-out, padding-left 0.3s ease-in-out;
    letter-spacing: 0.2px;
    /* Reduced letter spacing */
    width: 100%;
    text-align: left;
    white-space: nowrap;
    /* Prevent text wrapping */
}

.sidebar-item a:hover,
.sidebar-item .sidebar-link:hover {
    color: #1a73bc;
    padding-left: 30px;
}

/* Logout button specific styles */
.logout-button {
    background: none;
    border: none;
    cursor: pointer;
}

/* Styles for collapsed sidebar */
.sidebar-collapsed .sidebar-item a,
.sidebar-collapsed .sidebar-item .sidebar-link {
    justify-content: center;
    padding: 20px 0;
    font-size: 1.1rem;
}

.sidebar-collapsed .sidebar-item a::before,
.sidebar-collapsed .sidebar-item .sidebar-link::before {
    content: none;
}

/* Main Content Styling */
.main-content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex-grow: 1;
    background-color: #ffffff;
    transition: margin-left 0.3s ease-in-out;
    min-height: 100vh;
}

.sidebar-collapsed .main-content {
    margin-left: var(--sidebar-collapsed-width);
}



h3 {
    margin-top: 10px;
}

/* Utility Classes for Main Content */
.card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.card h2 {
    font-size: 1.8rem;
    color: #34495e;
    margin-bottom: 15px;
}

.card p {
    font-size: 1.1rem;
    color: #7f8c8d;
    line-height: 1.6;
}

/* Buttons inside the dashboard */
.button {
    padding: 10px 20px;
    background-color: #1abc9c;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.button:hover {
    background-color: #7fb3a9;
    /* Darker hover effect for buttons */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    :root {
        --sidebar-width: 100%;
        --sidebar-collapsed-width: 60px;
    }

    .dashboard-container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        position: static;
    }

    .sidebar-collapsed .sidebar {
        width: 100%;
    }

    .main-content,
    .sidebar-collapsed .main-content {
        margin-left: 0;
        padding: 10px;
    }

    .sidebar-toggle {
        display: none;
    }

    .sidebar-item a,
    .sidebar-item .sidebar-link {
        padding: 15px;
    }

    h1 {
        font-size: 2rem;
    }

    .card {
        padding: 15px;
        margin: 10px 0;
    }
}

/* Any specific styling for report form can go here */

/* Add this to your existing Dashboard.css */

/* Sidebar Toggle Button */
.sidebar-toggle {
    position: absolute;
    top: 80%;
    right: -16px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    background: linear-gradient(135deg, #65a8d2, #3f8ddb);
    border: 2px solid #9fd5ff;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.sidebar-toggle:hover {
    width: 40px;
    height: 40px;
    right: -20px;
    font-size: 20px;
    background: linear-gradient(135deg, #3f8ddb, #2980b9);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%) scale(1.1);
}

.sidebar-toggle:active {
    transform: translateY(-50%) scale(0.95);
}

.sidebar-collapsed .sidebar-toggle {
    right: -16px;
}

.sidebar-collapsed .sidebar-toggle:hover {
    right: -20px;
}

/* Remove the connecting line */
.sidebar-toggle::before {
    display: none;
}

/* Remove any conflicting styles */
.sidebar-links {
    /* Remove this class or update its styles if needed */
}

/* Update the sidebar item styles */
.sidebar-icon {
    font-size: 16px;
    /* Smaller icons when expanded */
    margin-right: 12px;
    min-width: 16px;
}

.sidebar-text {
    transition: opacity 0.3s ease;
}

/* Update collapsed sidebar styles */
.sidebar-collapsed .sidebar-text {
    display: none;
}

.sidebar-collapsed .sidebar-icon {
    font-size: 24px;
    margin-right: 0;
}

/* Remove the dot styles */
.sidebar-collapsed .sidebar-item a::before,
.sidebar-collapsed .sidebar-item .sidebar-link::before {
    content: none;
}

/* Update the link styles */
.sidebar-item a,
.sidebar-item .sidebar-link {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 20px;
    transition: color 0.2s ease-in-out, padding-left 0.3s ease-in-out;
    letter-spacing: 0.5px;
    width: 100%;
    text-align: left;
}

/* Update collapsed styles */
.sidebar-collapsed .sidebar-item a,
.sidebar-collapsed .sidebar-item .sidebar-link {
    justify-content: center;
    padding: 20px 0;
    font-size: 1.1rem;
}

/* Add tooltip styles */
.sidebar-item {
    position: relative;
}

.sidebar-collapsed .sidebar-item a:hover::after,
.sidebar-collapsed .sidebar-item .sidebar-link:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    margin-left: 10px;
    z-index: 1000;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .dashboard-container {
        flex-direction: column;
    }

    .sidebar {
        position: fixed;
        width: 250px;
        height: 100vh;
        left: -250px;
        transition: left 0.3s ease-in-out;
    }

    .sidebar.active {
        left: 0;
    }

    .main-content {
        margin-left: 0;
        margin-top: 60px;
        /* Height of mobile header */
        width: 100%;
        padding: 15px;
    }

    /* Mobile Header */
    .mobile-header {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: #65a8d2;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        z-index: 998;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .mobile-logo {
        color: white;
        font-size: 1.5rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .mobile-hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 999;
    }

    .mobile-hamburger span {
        width: 30px;
        height: 3px;
        background: white;
        border-radius: 10px;
        transition: all 0.3s linear;
    }

    /* Hide desktop sidebar toggle */
    .sidebar-toggle {
        display: none;
    }

    /* Overlay when sidebar is open */
    .sidebar-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 997;
    }

    .sidebar-overlay.active {
        display: block;
    }
}

.dvm-name {
    color: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    width: 100%;
    text-align: center;
}

/* Update for collapsed state */
.sidebar-collapsed .dvm-name {
    display: none;
}

/* Add new DVM name section */
.dvm-section {
    padding: 12px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
}

/* Update collapsed state */
.sidebar-collapsed .dvm-section {
    padding: 12px 0;
    justify-content: center;
}

.sidebar-collapsed .dvm-section {
    display: none;
}