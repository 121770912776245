.footer {
    background-color: #0078af;
    color: #ffffff;
    padding: 60px 0 20px;
    width: 100%;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding: 0 20px;
}

.footer-section h4 {
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 600;
}

.footer-section p {
    color: #eeeeee;
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 12px;
}

.footer-section ul li a {
    color: #cecece;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #ffffff;
}

.social-links {
    display: flex;
    gap: 15px;
}

.social-links a {
    color: #b3b3b3;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #ffffff;
}

.footer-bottom {
    max-width: 1200px;
    margin: 40px auto 0;
    padding: 20px 20px 0;
    border-top: 1px solid #d0d0d0;
    text-align: center;
}

.footer-bottom p {
    color: #e1e1e1;
    font-size: 0.8rem;
}

@media (max-width: 1024px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}

@media (max-width: 576px) {
    .footer-content {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .footer {
        padding: 40px 0 20px;
    }
}