.legal-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 0 20px;
    min-height: calc(100vh - 400px);
}

.legal-container h1 {
    color: #333;
    margin-bottom: 20px;
}

.legal-content {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.legal-content section {
    margin-bottom: 40px;
}

.legal-content h2 {
    color: #0078af;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.legal-content h3 {
    color: #444;
    font-size: 1.2rem;
    margin: 20px 0 10px;
}

.legal-content p {
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
}

.legal-content ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.legal-content li {
    margin-bottom: 10px;
    line-height: 1.5;
    color: #666;
}

.legal-content strong {
    color: #444;
}

@media (max-width: 768px) {
    .legal-content {
        padding: 20px;
    }

    .legal-container {
        margin: 20px auto;
    }
}