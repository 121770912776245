.manage-account-container {
    width: calc(100% - 48px);
    max-width: 1200px;
    margin: 24px auto;
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}

/* Add responsive styling to match Profile.css */
@media (max-width: 768px) {
    .manage-account-container {
        width: 100%;
        margin: 0;
        padding: 16px;
        border-radius: 0;
        margin-top: 60px;
        /* Account for mobile header */
        box-shadow: none;
    }

    .user-info-section {
        padding: 16px;
        border-radius: 12px;
    }

    .user-profile {
        flex-direction: column;
        text-align: center;
        gap: 12px;
    }

    .user-details {
        width: 100%;
        text-align: center;
    }

    .detail-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .detail-item span:first-child {
        font-size: 0.8rem;
        color: #666;
    }

    .detail-item span:last-child {
        font-size: 1rem;
        width: 100%;
    }

    .confirm-buttons {
        flex-direction: column;
        gap: 8px;
    }

    .confirm-delete-button,
    .cancel-delete-button,
    .delete-account-button,
    .back-button {
        width: 100%;
        padding: 12px;
        font-size: 1rem;
    }

    .danger-zone {
        margin-top: 24px;
        padding: 16px;
    }

    .confirm-delete p {
        font-size: 0.9rem;
        line-height: 1.4;
    }
}

.manage-account-container h2 {
    color: #333;
    margin-bottom: 2rem;
}

.error-message {
    background-color: #fee;
    color: #c00;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.danger-zone {
    margin-top: 2rem;
    padding: 1.5rem;
    border: 1px solid #ffcdd2;
    border-radius: 8px;
    background-color: #fff5f5;
}

.danger-zone h3 {
    color: #c62828;
    margin-bottom: 1rem;
}

.delete-account-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.delete-account-button:hover {
    background-color: #c82333;
}

.confirm-delete {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #dc3545;
    border-radius: 4px;
}

.confirm-delete p {
    color: #dc3545;
    margin-bottom: 1rem;
    font-weight: 500;
}

.confirm-buttons {
    display: flex;
    gap: 1rem;
}

.confirm-delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-delete-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.back-button {
    margin-top: 2rem;
    background-color: transparent;
    color: #666;
    border: 1px solid #ddd;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.back-button:hover {
    background-color: #f5f5f5;
    color: #333;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.user-info-section {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.user-profile {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;
}

.profile-picture {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 1rem;
}

.user-details h3 {
    margin: 0;
    color: #333;
}

.user-email {
    color: #666;
    margin: 0.25rem 0 0 0;
}

.subscription-info {
    margin-top: 1rem;
}

.subscription-info h3 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.subscription-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background: white;
    border-radius: 4px;
}

.detail-item span:first-child {
    color: #666;
    font-weight: 500;
}

.status-active {
    color: #28a745;
    font-weight: 500;
}

.status-inactive {
    color: #dc3545;
    font-weight: 500;
}