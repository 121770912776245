/* General Styles */
body {
    /* background-color: #f0f4f8; */
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: #ecf0f1;
}

::-webkit-scrollbar-thumb {
    background-color: #bdc3c7;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #95a5a6;
}

/* Container for the form and preview */
.report-container {
    display: flex;
    gap: 20px;
    max-width: 100%;
    height: calc(100vh - 60px);
    margin: 30px auto;
    padding: 0 15px;
    animation: fadeIn 0.5s ease-in;
    overflow-x: hidden;
}

/* Form Styles */
.report-form {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 50%;
    overflow-y: auto;
}

.report-form:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.form-label {
    display: block;
    margin-bottom: 2px;
    font-weight: 700;
    color: #2c3e50;
    font-size: 0.9rem;
    margin-top: 8px;
}

.physical-exam-input {
    min-height: 360px;
    max-height: 400px;
    resize: vertical;
}

.form-input:focus,
.form-select:focus,
.form-textarea:focus {
    border-color: #3498db;
    outline: none;
    background-color: #ecf0f1;
}

.form-input,
.form-select,
.form-textarea {
    width: 100%;
    padding: 6px 8px;
    margin-bottom: 4px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #f9fafc;
    color: #333;
    font-size: 0.9rem;
    line-height: 1.2;
    transition: all 0.3s ease;
    box-sizing: border-box;
    resize: vertical;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.form-input::placeholder,
.form-select::placeholder,
.form-textarea::placeholder {
    color: #94a3b8;
    font-style: italic;
    opacity: 0.6;
}

.form-input,
.form-select {
    height: 32px;
}

.form-textarea {
    min-height: 60px;
}

.submit-button {
    padding: 8px 12px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.submit-button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
    background-color: #2980b9;
}

.add-button:hover {
    background-color: #5dade2;
}

.remove-button:hover {
    background-color: #e74c3c;
}

/* Error Messages */
.error-message {
    color: #e74c3c;
    font-size: 0.8rem;
}

/* Report Preview */
.report-preview {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.report-preview-header {
    background-color: #5bade3;
    color: #ffffff;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

.report-preview-header h3 {
    margin: 0;
    font-size: 1rem;
    color: #ffffff;
}

/* Restrict loading container to report preview area */
.report-preview-content {
    position: relative;
    height: calc(100% - 100px);
    background-color: white;
    overflow-y: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.report-text-editor {
    display: none;
}

[data-slate-editor="true"] {
    padding: 20px;
    min-height: 100%;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.1;
    position: relative;
    overflow-anchor: none;
}

[data-slate-node="element"] {
    margin: 0.2em 0;
}

[data-slate-node="element"][data-slate-type="heading"] {
    font-weight: bold;
    font-size: 1.1em;
    margin: 0.6em 0 0.1em;
    color: #2c3e50;
}

[data-slate-node="element"][data-slate-type="indented"] {
    padding-left: 20px;
    margin: 0.2em 0;
}

[data-slate-node="element"][data-slate-type="paragraph"] {
    margin: 0.2em 0;
}

.report-preview-footer {
    background-color: #f8f9fa;
    padding: 10px 15px;
    border-top: 1px solid #e9ecef;
}

.button-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
}

.submit-button,
.copy-button,
.download-pdf-button,
.clear-button,
.back-button-patient {
    padding: 6px 12px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    min-width: 100px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Primary action button */
.submit-button {
    background-color: #4a5568;
    color: white;
}

.submit-button:hover {
    background-color: #2d3748;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Success action buttons */
.copy-button,
.download-pdf-button {
    background-color: #66b4e8;
    color: #ffffff;
}

.copy-button:hover,
.download-pdf-button:hover {
    background-color: #3d7e9c;
    color: white;
}

/* Secondary action buttons */
.clear-button,
.back-button-patient {
    background-color: #fc8181;
    color: #742a2a;
}

.clear-button:hover,
.back-button-patient:hover {
    background-color: #f56565;
    color: white;
}

/* Disabled state */
.submit-button:disabled,
.copy-button:disabled,
.download-pdf-button:disabled {
    background-color: #e2e8f0;
    color: #a0aec0;
    cursor: not-allowed;
    border-color: #edf2f7;
}

/* Copy message tooltip */
.copied-message {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4a5568;
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.report-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    color: #818181c1;
    padding-bottom: 80px;
}

.report-placeholder-text {
    padding: 40px 20px;
}

.report-placeholder h2 {
    font-size: 1.8rem;
    margin-bottom: 12px;
    color: #64748b;
}

.report-placeholder p {
    font-size: 1.2rem;
    margin: 0;
    color: #64748b;
}

.ai-warning {
    margin-top: 15px;
    padding: 4px 8px;
    background-color: #cdf1ffad;
    border: 1px solid #bae7ff;
    border-radius: 4px;
    color: #626262;
    text-align: left;
}

.ai-warning strong {
    display: block;
    margin-bottom: 2px;
    font-size: 0.6rem;
    text-align: left;
}

.ai-warning p {
    margin: 1px 0;
    font-size: 0.6rem;
    line-height: 1.1;
    color: #626262;
    text-align: left;
}

@media (max-width: 768px) {
    .report-container {
        flex-direction: column;
        padding: 10px;
    }

    .report-form,
    .report-preview {
        max-width: 100%;
        margin: 10px 0;
    }

    .button-container {
        flex-wrap: wrap;
    }

    .submit-button,
    .copy-button,
    .download-pdf-button,
    .clear-button {
        width: 100%;
        margin: 5px 0;
    }
}

@media (max-width: 1200px) {
    .report-container {
        flex-direction: column;
        height: auto;
    }

    .report-form,
    .report-preview {
        max-width: 100%;
    }

    .report-preview {
        height: 50vh;
        margin-top: 20px;
    }
}

/* Add this new class for the generate report button */
.generate-report-button {
    padding: 4px 12px;
    height: 32px;
    background: linear-gradient(145deg, #3498db, #2980b9);
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
    text-transform: none;
    letter-spacing: 0.5px;
    margin: 0;
}

.generate-report-button:hover {
    background: linear-gradient(145deg, #2980b9, #2472a4);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.generate-report-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
}

.generate-report-button:disabled {
    background: linear-gradient(145deg, #95a5a6, #7f8c8d);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Add these styles for the preview button container */
.preview-button-group {
    display: flex;
    gap: 10px;
    margin-right: auto;
    align-items: center;
}

.continue-button {
    padding: 6px 12px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    min-width: 100px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: #4a5568;
    color: white;
}

.continue-button:hover {
    background-color: #416c9e;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.continue-button:after {
    content: "→";
    margin-left: 4px;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    line-height: 1;
    opacity: 0.8;
    transition: opacity 0.2s ease;
}

.close-button:hover {
    opacity: 1;
}

.form-field {
    margin-bottom: 8px;
    position: relative;
}

.field-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2px;
}

.remove-field-button {
    background: none;
    border: none;
    color: #ff4444;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0 0.5rem;
}

.add-field-button {
    background: #f0f0f0;
    border: 1px dashed #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    color: #666;
    cursor: pointer;
    margin-bottom: 1rem;
}

.add-field-button:hover {
    background: #e8e8e8;
    color: #333;
}

.field-toggle {
    margin-bottom: 1rem;
}

.form-field-container {
    width: 100%;
    margin-bottom: 8px;
}

.field-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2px;
}

.toggle-switch {
    margin-top: 4px;
    flex-shrink: 0;
}

.form-field-container {
    width: 100%;
    margin-bottom: 8px;
}

.field-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2px;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    margin-top: 22px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-top: 2px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

.form-input.disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
}

/* Add this new container for input and toggle */
.input-toggle-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: relative;
}

/* Reset general input width to original */
.input-toggle-wrapper .form-input {
    width: calc(100% - 60px);
    /* Original width for most inputs (space for toggle only) */
}

/* Special case for physical exam input */
.input-toggle-wrapper .physical-exam-input {
    width: calc(100% - 140px);
    /* Extra space only for physical exam input */
}

.toggle-switch {
    margin-top: 4px;
    flex-shrink: 0;
}

.report-limit-info {
    background-color: #f8f9fa;
    padding: 8px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 0.9em;
    color: #666;
}

.report-limit-info.near-limit {
    color: #856404;
    background-color: #fff3cd;
}

.report-limit-info.at-limit {
    color: #721c24;
    background-color: #f8d7da;
}

/* Ensure content is properly padded */
.report-preview-content {
    padding: 0;
    height: calc(100% - 100px);
    overflow-y: auto;
}

[contenteditable="true"] {
    cursor: text;
    outline: none;
}

.slate-editor {
    min-height: 100%;
    padding: 20px;
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.editor-container {
    flex: 1;
    overflow-y: auto;
    background-color: white;
}

.report-preview-content>div {
    height: auto;
    min-height: 100%;
}

::selection {
    background-color: #b3d4fc;
    text-shadow: none;
}

/* Add styles for the editor wrapper */
.editor-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    scroll-padding: 20px;
}

/* Keep original physical exam input styles separate */
/* .physical-exam-input {
    min-height: 340px;
    max-height: 400px;
    resize: vertical;
} */

/* Handle all other textareas */
.input-toggle-wrapper textarea.form-input:not(.physical-exam-input) {
    height: 32px;
    min-height: 32px;
    max-height: 300px;
    overflow-y: auto;
    padding-top: 6px;
    line-height: 1.2;
}

.limit-warning-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    padding: 25px 20px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #e2e8f0;
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
    max-width: 300px;
}

.limit-warning-popup .close-warning {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #64748b;
    padding: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.limit-warning-popup .close-warning:hover {
    background-color: #f1f5f9;
}

.limit-warning-popup .upgrade-link {
    background: none;
    border: none;
    color: #2563eb;
    font-weight: 500;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
}

.limit-warning-popup .upgrade-link:hover {
    color: #1d4ed8;
}

.limit-warning-popup p {
    margin: 0;
    color: #4b5563;
}

.limit-warning-popup a {
    color: #2563eb;
    text-decoration: none;
    font-weight: 500;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.report-form-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1000;
    overflow: visible;
}

.report-form-loading-spinner {
    width: 100px;
    height: 100px;
    background-image: url('../../public/PW.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: fade 3s ease-in-out infinite;
    margin-bottom: 20px;
    display: block;
}

@keyframes fade {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }
}

.loading-text {
    font-size: 1rem;
    color: #3498db;
    text-align: center;
    margin-top: 10px;
    animation: none;
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Template Control Styles */
.template-controls {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    width: auto;
    /* Remove fixed width */
    margin-left: 0;
    /* Align with left edge */
}

.save-template-button,
.default-template-button {
    padding: 6px 12px;
    height: 28px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    /* Fixed width instead of flex: 1 */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.save-template-button {
    background-color: #66b4e8;
    color: #ffffff;
}

.save-template-button:hover {
    background-color: #3d7e9c;
}

.default-template-button {
    background-color: #4f7080;
    color: #ffffff;
}

.default-template-button:hover {
    background-color: #6787aa;
    color: white;
}

/* Update physical exam container */
.physical-exam-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Keep input width consistent */
.input-toggle-wrapper .form-input {
    width: calc(100% - 60px);
}

/* Mobile Styles (won't affect desktop) */
@media screen and (max-width: 767px) {
    .report-container {
        flex-direction: column;
        padding: 10px;
        margin: 10px 0;
        height: auto;
    }

    .report-form,
    .report-preview {
        max-width: 100%;
        margin: 10px 0;
    }

    .report-preview {
        height: 50vh;
    }

    .button-container {
        flex-direction: column;
        gap: 8px;
    }

    .submit-button,
    .copy-button,
    .download-pdf-button,
    .clear-button,
    .back-button-patient {
        width: 100%;
        margin: 0;
    }

    .input-toggle-wrapper {
        flex-direction: column;
        gap: 15px;
        position: relative;
        padding-top: 30px;
        /* Space for toggle */
    }

    .input-toggle-wrapper .form-input,
    .input-toggle-wrapper .physical-exam-input {
        width: 100%;
        min-height: 64px;
        /* Double the height */
    }

    .toggle-switch {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 0;
    }

    /* Adjust physical exam input specifically */
    .physical-exam-input {
        min-height: 400px;
        /* Keep large size for physical exam */
    }

    /* Adjust textarea heights */
    .input-toggle-wrapper textarea.form-input:not(.physical-exam-input) {
        height: 64px;
        min-height: 64px;
    }

    .template-controls {
        flex-direction: column;
        width: 100%;
    }

    .save-template-button,
    .default-template-button {
        width: 100%;
    }

    .button-container {
        flex-direction: column;
        gap: 8px;
    }

    /* Make all buttons smaller except generate-report */
    .submit-button,
    .copy-button,
    .download-pdf-button,
    .clear-button,
    .back-button-patient {
        width: 100%;
        margin: 0;
        height: 28px;
        /* Smaller height */
        font-size: 0.75rem;
        /* Smaller font */
        min-width: unset;
    }

    /* Make generate report button larger */
    .generate-report-button {
        width: 100%;
        height: 40px;
        /* Larger height */
        font-size: 0.9rem;
        /* Larger font */
        margin: 10px 0;
        /* Add some spacing */
        font-weight: 600;
    }

    .report-preview-header {
        position: relative;
        z-index: 1;
    }
}