/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Basic link reset */
a {
    text-decoration: none;
    color: inherit;
}

/* Reset list styles */
ul {
    list-style-type: none;
}

/* Reset image behavior */
img {
    max-width: 100%;
    height: auto;
}

/* Basic form reset */
input,
textarea,
select {
    font-family: inherit;
}